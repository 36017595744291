@use "../assets/base.scss";

.footer-wrapper {
    font-family: base.$font-primary;
    background-color: transparent;


    a {
        text-decoration: none;
        margin-right: 15px;
    }

    p {
        font-weight: 100;
        margin: 7px 0 7px 0;
    }

}

.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 55px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill: #02b8a0;
}

.footer-social-link.linkedin {
    height: 26px;
    left: 4px;
    top: 11px;
    width: 26px;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
}


.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

.footer-social-icon-svg {
    display: block;
}

.footer-social-icon-path {
    fill: #fffff2;
    transition: fill .2s;
}

.footer-social-link.twitter {
    height: 28px;
    left: 61px;
    top: 3px;
    width: 28px;
}

.footer-social-link.youtube {
    height: 24px;
    left: 123px;
    top: 12px;
    width: 24px;
}

.footer-social-link.instagram {
    height: 34px;
    left: 176px;
    top: 10px;
    width: 34px;
}

.footer-copyright {
    background-image: linear-gradient(to right, #8360c3, #2ebf91);
    color: #fff;
    padding: 15px 30px;
    text-align: center;
    margin: 0 auto;

}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 0.5fr 4fr 0.5fr 1fr;

}

.footer-copyright-text {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width: base.$mobile-breakpoint1) {
    .footer-copyright-wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        display: grid;
        grid-template-columns: 1fr;
    }

    .footer-copyright-mailto-wrapper {
        margin-left: 15px;
    }

    .footer-copyright-privacy-wrapper {
        margin-left: 15px;
    }
}




@media (min-width:320px) and (max-width:479px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 50px 15px;
        position: relative;
    }

    p {
        font-size: 10px;
    }
}

@media (min-width:480px) and (max-width:599px) {

    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 50px 15px;
        position: relative;
    }

    p {
        font-size: 10px;
    }
}

@media (min-width:600px) and (max-width: 800px) {

    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 50px 15px;
        position: relative;
    }

    p {
        font-size: 10px;
    }
}


@media (min-width: 760px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 50px 15px;
        position: relative;

    }

    .footer-wave-svg {
        height: 50px;
    }

    .footer-content-column {
        width: 24.99%;
    }
}